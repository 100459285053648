.static-data-container{
    border: 1px solid black;
    padding-left: 2rem;
    padding-top: 2rem;
    display: flex;
}
.ml-2{
    margin-left: 2rem;
}

.contact-image{
    width: 382.48px;
    height: 268.58px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
}

.contact-container{
    margin-top: 2rem;
  }
  .contact-title{
      color: black;
  }

  @media (min-width: 300px) and (max-width:549px) {
    .contact-container{
        margin-top: 65px;
      }
      .contact-image{
        width: 317.48px;
        height: 222.94px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.5rem;
    }
    .static-data-container{
        border: 1px solid black;
        padding-left: 2rem;
        padding-top: 2rem;
        display: block;
        margin-bottom: 2rem;
    }
  }