.product-card{
    background-color: #fff;
    width: 100%;
    height: 564px;
    margin-top: 3rem;
    margin-bottom: 9rem;
    padding: 2rem;
}

h5.product-name{
    color: #000;
}

.product-separator{
    padding-right: 3rem;
}

.product-image{
    width: 100px;
    height: 100px;
}
.product-image-container{
    width: 100%;
    padding-left: 11rem;
}
.product-description{
    text-align: justify;
    text-justify: inter-word;
}
.product-status{
    text-align: center;
    text-transform: uppercase;
}
.product-buttons{
    width: 100%;
    display: flex;
}

.product-buttons .bordered-button{
    margin-left: 0rem;
    width: 100%;
}
.product-buttons .filled-button{
    margin-left: 2rem !important;
}
.merch-container .product-list-container .columns{
    margin-left: 0px !important;
    margin-right: 2% !important;
}
.merch-container .product-list-container .product-separator{
    padding-right: 6rem;
}
.modal-image{
    width: 312.7px;
    height: 219.58px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.product-modal-title{
    color:#000;
}
.modal-product-specs{
    list-style-type:disc;
}

.button-family .bordered-button{
    margin-top: 1rem !important;
}
.mt0{
    margin-top: 0px;
}

@media (min-width: 300px) and (max-width:549px) {
    .product-card {
        background-color: #fff;
        width: 100%;
        height: 518px;
        margin: 0 0 1.5rem 0;
        padding: 2rem;
    }

    .product-separator{
        padding-left: 0rem;
        padding-right: 4rem;
    }
    .merch-container{
        margin-top: 9rem;
    }

    .merch-container .product-list-container .product-separator{
        padding-left: 0rem;
        padding-right: 4rem;
    }

}
