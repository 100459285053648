.brand-h1{
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.txt-centered{
    text-align: center;
}
.my-name{
    margin-bottom: 0px;
}

.p-container{
    padding-left: 7rem;
    padding-right: 7rem;
}

.img1{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 260px;
    margin-bottom: 2rem;    
}
.img2{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 311px;
    margin-bottom: 0rem;    
}
.img3{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 311px;
    margin-bottom: 2rem;    
}

.img4{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 226px;
    margin-bottom: 2rem;
    padding-left: 2rem; 
}
.d-link{
    color: #fff;
}

@media (min-width: 300px) and (max-width:549px) {
    .about-container{
        margin-top: 3.2rem;
    }
    .p-container{
        padding-left: 0rem;
        padding-right: 0rem;
    }
    .img1{
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 212px;
        margin-bottom: 2rem;    
    }
    .img2{
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 181px;
        margin-bottom: 0rem;    
    }
    .img3{
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 181px;
        margin-bottom: 2rem;    
    }
    
    .img4{
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 181px;
        margin-bottom: 2rem;
        padding-left: 0rem; 
    }
}