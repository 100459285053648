html {
  min-height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;;
  background-color: #000;
  min-height: 100%;
}
h1 {
  color: white;
  padding: 3rem 0;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', 'semi-bold';
  letter-spacing: 1px;
}
h2, h3,h4,h5,h6{
  color: white;
  padding: 0.5rem 0;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', 'semi-bold';
  letter-spacing: 1px;
}
p.bb {
  color: #fff;
}
p.wb {
  color: #000;
}
.pinguin {
  background-color: #fff;
  padding: 1rem;
}

label {
  color: #000;
  font-family: 'Montserrat', 'semi-bold';
}

input[type="date"],
input[type="time"],
input[type="text"],
input[type="password"],
input[type="email"]
 {
    height: 38px;
    padding: 6px 10px;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 0px;
    box-shadow: none;
    box-sizing: border-box;
}

textarea {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 0px;
  box-shadow: none;
  box-sizing: border-box;
}

.bordered-container{
  width: 86.5%;
  padding: 2rem;
  border: 2px solid #000;
}

.container{
  max-width: 1920px !important;
}

.bordered-button{
  display: inline-block;
  height: 38px;
  padding: 0 30px;
  color: #000;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: .1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 0px !important;
  border: 1px solid #000;
  cursor: pointer;
  box-sizing: border-box;
  margin: 1rem;
  transition: 0.2s background-color linear, 0.1s color linear;
}
.bordered-button-inverse{
  display: inline-block;
  height: 38px;
  padding: 0 30px;
  color: #fff;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: .1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 0px !important;
  border: 1px solid #fff;
  cursor: pointer;
  box-sizing: border-box;
  margin: 1rem;
  transition: 0.2s background-color linear, 0.1s color linear;
}
.filled-button{
  display: inline-block;
  height: 38px;
  padding: 0 30px;
  color: #fff;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: .1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: #000;
  border-radius: 0px !important;
  border: 1px solid #000;
  cursor: pointer;
  box-sizing: border-box;
  margin: 1rem;
  transition: 0.2s background-color linear, 0.1s color linear;
}
.filled-button:hover{
  color: #000 !important;
  background-color: #fff !important;
  border: 1px solid #000 !important;
}
.bordered-button:hover{
  color: #fff !important;
  background-color: #000 !important;
  border: 1px solid #000 !important;
}
.bordered-button-inverse:hover{
  color: #000 !important;
  background-color: #fff !important;
  border: 1px solid #fff !important;
}

.button-family{
  position: relative;
  float: right;
  margin-top: 8rem;
  width: 250.688px;
  display: flex;
  justify-content: space-between;
  padding-right: 3rem;
}

li a {
  font-family: 'Montserrat', 'semi-bold';
  font-size: 2.2rem !important;
}

li a:hover{
  color: rgb(153, 146, 146) !important;
}



.elements{
  margin-top: 7rem;
  width: 64%;
  
}
.elements ul{
  position: relative;
  float: right;
}
.footer{
  width: 100%;
    background-color: #fff;
    margin-top: 3rem;
    margin-bottom: 0px;
    top: 100%;
    /*position: absolute;*/
}

.footer .container .navbar-link {
  color: #000;
}
.ft-slogan{
  color:#000;
  font-family: 'Montserrat', Italic;
  font-style: italic;
}
.social-icons{
  color:#fff;
  font-size: 1.7rem;
  margin-top: 24%;
}
.social-link{
  background-color: #000;
  border-radius: 100px;
  height: 36px;
  width: 36px;
  text-align: center;
  transition: 0.2s background-color linear, 0.1s  linear;
}
.social-link:hover{
  background-color: rgb(102, 100, 100);
}
.last-post-section h3{
  text-align: left;
}
.products-section h3{
  text-align: left;
}
.seven.columns h4{
  text-align: left;
}
/* Other shitty css classes*/

/*phone screens*/
.navbar,
  .navbar-spacer {
    display: block;
  }
.navbar-link{
  text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .2rem;
    text-decoration: none;
    color: #fff;
}

.footer .container .spacer{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer .container .brands{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 23%;
  padding-right: 23%;
}
.footer .container .footer-leyend{
  text-align: center;
  font-size: 13px;
}
.last-post-section{
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.home-container{
  margin-top: 2rem;
}

@media (min-width: 300px) and (max-width:549px) {
  .brand-image{
    width: 334px;
  }
  .brand {
    max-height: 150px;
  }
  .elements{
    margin-top: 10px;
  }
  .elements ul{
    position: absolute;
    display: flex;
    padding-left:0px;
    text-align: center;
  }
  .navbar-list{
    padding-left: 0px;
    padding-right: 0px;
    left: 8px;
    top: 144px;
    margin-bottom: 0px;
    width: 96%;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
  li a {
    font-family: 'Montserrat', 'semi-bold';
    font-size: 1.2rem !important;
  }
  .footer{
    width: 100%;
      background-color: #fff;
      margin-top: 3rem;
      margin-bottom: 0px;
      top: 100%;
      /*position: absolute;*/
  }
  .footer .container .spacer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 45px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.ft-slogan {
  color: #000;
  font-family: 'Montserrat', Italic;
  font-style: italic;
  margin-top: 1rem;
}
.home-container{
  margin-top: 65px;
}

.blog-post-container{
  margin-top: 65px;
}

.last-post-section h3{
  text-align: center;
}
.products-section h3{
  text-align: center;
}
.seven.columns h4{
  text-align: center;
}
.bordered-button-inverse{
  margin-left: 10rem;
}
}

/* Larger than phone */
@media (min-width: 550px) {
  .header {
    margin-top: 18rem; }
  .value-props {
    margin-top: 9rem;
    margin-bottom: 7rem; }
  .value-img {
    margin-bottom: 1rem; }
  .example-grid .column,
  .example-grid .columns {
    margin-bottom: 1.5rem; }
  .docs-section {
    padding: 6rem 0; }
  .example-send-yourself-copy {
    float: right;
    margin-top: 12px; }
  .example-screenshot-wrapper {
    position: absolute;
    width: 48%;
    height: 100%;
    left: 0;
    max-height: none; }
}

/* Larger than tablet */
@media (min-width: 750px) {
  .bordered-container{
    width: 96%;
    padding: 2rem;
    border: 2px solid #000;
  }  
  /* Navbar */
  .navbar + .docs-section {
    border-top-width: 0; }
  .navbar,
  .navbar-spacer {
    display: block;
    width: 100%;
    z-index: 99;
    max-height: 180px;
  }
  .nav-row{
    display:flex;
  }
  .navbar-spacer {
    display: none; }
  .navbar > .container {
    width: 100%; }
  .navbar-list {
    list-style: none;
    margin-bottom: 0; }
  .navbar-item {
    position: relative;
    float: left;
    margin-bottom: 0; }
  .navbar-link {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: .2rem;
    margin-right: 35px;
    text-decoration: none;
    line-height: 6.5rem;
    color: #fff; }
  .navbar-link.active {
    color: #33C3F0; }
  .has-docked-nav .navbar {
    position: fixed;
    top: 0;
    left: 0; }
  .has-docked-nav .navbar-spacer {
    display: block; }
  /* Re-overiding the width 100% declaration to match size of % based container */
  .has-docked-nav .navbar > .container {
    width: 80%; }

  /* Popover */
  .popover.open {
    display: block;
  }
  .popover {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    top: 92%;
    left: -50%;
    -webkit-filter: drop-shadow(0 0 6px rgba(0,0,0,.1));
       -moz-filter: drop-shadow(0 0 6px rgba(0,0,0,.1));
            filter: drop-shadow(0 0 6px rgba(0,0,0,.1)); }
  .popover-item:first-child .popover-link:after, 
  .popover-item:first-child .popover-link:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .popover-item:first-child .popover-link:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px; }
  .popover-item:first-child .popover-link:before {
    border-color: rgba(238, 238, 238, 0);
    border-bottom-color: #eee;
    border-width: 11px;
    margin-left: -11px; }
  .popover-list {
    padding: 0;
    margin: 0;
    list-style: none; }
  .popover-item {
    padding: 0;
    margin: 0; }
  .popover-link {
    position: relative;
    color: #222;
    display: block;
    padding: 8px 20px;
    border-bottom: 1px solid #eee;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.0rem;
    font-weight: 600;
    text-align: center;
    letter-spacing: .1rem; }
  .popover-item:first-child .popover-link {
    border-radius: 4px 4px 0 0; }
  .popover-item:last-child .popover-link {
    border-radius: 0 0 4px 4px;
    border-bottom-width: 0; }
  .popover-link:hover {
    color: #fff;
    background: #33C3F0; }
  .popover-link:hover,
  .popover-item:first-child .popover-link:hover:after {
    border-bottom-color: #33C3F0; }
    .footer .container .spacer{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .footer .container .brands{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 40%;
      padding-right: 43%;
    }
    
    .footer .container .footer-leyend{
      text-align: center;
    }
}

.alert-error {
  background-color: white;
  color: red;
  padding: 1rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
} 