.blogImage{
    width: 100%;
}

.blog-container .blog-list-container .columns {
    margin-right: 2% !important;
    margin-left: 0% !important;
}

.card-pinguin{
    display: none;
    position: absolute;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0%;
    width: inherit;
    height: 98%;
    text-align: center;
    transition: 0.5s  linear, 0.1s  linear;
}

.dropdown:hover .card-pinguin {
    display: block;
  }


.dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
  }


.dropdown:hover .card-pinguin {
    display: block;
  }

.bordered-button{
    margin: 0px !important;
}
.buttons-section{
    display: flex;
    justify-content: space-between;
    padding-left: 22%;
    padding-right: 22%;
}

.text-section{
    text-align: center;
    padding-top: 27%;
}
.text-section p{
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
}

.bordered-button{
    padding: 0 39px !important;
}

.filled-button.w{
    background-color: #fff;
    color: black;
}

.filled-button.w:hover{
    border-color: #02e8d9 !important;
    background-color: #929292 !important;
}

.newBlogPostSection{
    padding-left: 45%; 
    padding-right: 48%;
}

.inline{
    display: inline;
}

.blog-container{
    margin-top: 2rem;
}

  @media (min-width: 300px) and (max-width:549px) {
    .card-pinguin{
        display: none;
        position: absolute;
        z-index: 5;
        top: 0%;
        width: inherit;
    }
    .buttons-section{
        display: flex;
        justify-content: space-between;
        padding-left: 5%;
        padding-right: 5%;
    }
    .newBlogPostSection{
        padding-left: 37%; 
        padding-right: 48%;
    }
    .blog-container{
        margin-top: 65px;
    }
  }
